import React, { useState } from "react";
import { RRule, Weekday } from "rrule";
import Button from "@atlaskit/button";
import styled from "styled-components";
import { ByWeekday } from "rrule/dist/esm/types";

interface WeekdayOption {
  label: string;
  value: Weekday;
}

const WeekdayOptions: WeekdayOption[] = [
  { value: RRule.SU, label: "S" },
  { value: RRule.MO, label: "M" },
  { value: RRule.TU, label: "T" },
  { value: RRule.WE, label: "W" },
  { value: RRule.TH, label: "T" },
  { value: RRule.FR, label: "F" },
  { value: RRule.SA, label: "S" },
];

interface WeekdaySelectorProps {
  selectedWeekdays: ByWeekday | ByWeekday[] | null | undefined;
  onWeekdayClick: (weekdays: Weekday[]) => void;
}

export function WeekdaySelector({
  selectedWeekdays,
  onWeekdayClick,
}: WeekdaySelectorProps) {
  const [weekdaysRecurrence, setWeekdaysRecurrence] = useState<Weekday[]>(
    mapByWeekdayValueToWeekdays(selectedWeekdays)
  );

  const toggleWeekday = (weekday: Weekday) => {
    if (!weekdaysRecurrence.find((day) => day.equals(weekday))) {
      const update = [...weekdaysRecurrence, weekday];
      setWeekdaysRecurrence(update);
      onWeekdayClick(update);
    } else {
      const update = weekdaysRecurrence.filter((day) => !day.equals(weekday));
      setWeekdaysRecurrence(update);
      onWeekdayClick(update);
    }
  };

  return (
    <Wrapper>
      {WeekdayOptions.map((day) => (
        <WeekdayButton
          key={day.value.toString()}
          appearance={
            weekdaysRecurrence.find((weekday) => weekday.equals(day.value))
              ? "primary"
              : "default"
          }
          onClick={() => toggleWeekday(day.value)}
        >
          {day.label}
        </WeekdayButton>
      ))}
    </Wrapper>
  );
}

function mapByWeekdayValueToWeekdays(
  byweekday: ByWeekday | ByWeekday[] | null | undefined
) {
  return Array.isArray(byweekday)
    ? byweekday.map((day) =>
        (day as Weekday).weekday
          ? new Weekday((day as Weekday).weekday)
          : new Weekday(day as number)
      )
    : [];
}

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
`;

const WeekdayButton = styled(Button)`
  width: 38px;
`;
