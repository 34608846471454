import { formatInTimeZone } from "date-fns-tz";
import React from "react";
// eslint-disable-next-line import/no-unresolved
import { stripHtml } from "string-strip-html";
import { Attendee, JiraField, JiraProject } from "../types";
import { GoogleDateTimeFormat, Event } from "./calendar";
import { JiraIssueResponse, getIssueFields } from "./jira-api";

export function getErrorMessage(error: unknown) {
  if (error instanceof Error) return error.message;
  return String(error);
}

export function getDisplayDate(
  dateString: string | undefined,
  timeZone?: string
) {
  if (!dateString) {
    return "No Date";
  }
  return new Date(dateString).toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
    timeZone,
  });
}

export function getDisplayDateWithTime(dateString: string, timeZone?: string) {
  return `${getDisplayDate(dateString, timeZone)} ${new Date(
    dateString
  ).toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    timeZone,
  })} `;
}

export function parseGoogleDate(date: GoogleDateTimeFormat, timeZone: string) {
  const dateTimeFormatString = "yyyy-MM-dd HH:mm";
  const dateFormatString = "yyyy-MM-dd";

  let dateString, withTime;

  if (date.dateTime) {
    dateString = date.dateTime;
    withTime = true;
  } else {
    dateString = date.date;
    withTime = false;
  }

  const formattedDate = formatInTimeZone(
    dateString ? new Date(dateString) : new Date(),
    date.timeZone || timeZone,
    withTime ? dateTimeFormatString : dateFormatString
  );

  return {
    date: withTime ? formattedDate.split(" ")[0] : formattedDate,
    time: withTime ? formattedDate.split(" ")[1] : undefined,
  };
}

export function prepareGoogleDate(
  date: string,
  timeZone?: string,
  time?: string
) {
  if (time) {
    return { dateTime: `${date}T${time}:00`, timeZone };
  } else {
    return { date: date };
  }
}

export enum Screen {
  MAIN,
  SETTINGS,
  FEEDBACK,
}

export function getTimeList() {
  return Array.from({ length: 24 }, (h, i) => {
    const hh = i.toString().padStart(2, "0");
    return [`${hh}:00`, `${hh}:15`, `${hh}:30`, `${hh}:45`];
  }).flat();
}

export function getClosestTime(time: string) {
  const allTimes = getTimeList();
  const foundTime = allTimes.find((t: string) => t >= time);
  return foundTime ? foundTime : time;
}

export const scrollToRef = (ref: React.RefObject<HTMLElement>) => {
  if (ref && ref.current && ref.current.scrollIntoView) {
    return ref.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }
};

export class ErrorWithStatus extends Error {
  public status: number;
  constructor(status: number, message: string) {
    super(message);
    this.status = status;
  }
}

export function getErrorStatus(error: unknown) {
  if (error instanceof ErrorWithStatus) return error.status;
}

export function getIframeUrlParameterByName(name: string) {
  const params = new URL(document.location.toString()).searchParams;
  return params.get(name);
}

export function getAllAttendeesEmails(events: Event[]) {
  return [
    ...new Set(
      events.flatMap((event) => {
        return event.attendees?.map((attendee) => attendee.email) || [];
      })
    ),
  ];
}

export function getAllAttendeesNormalized(events: Event[]) {
  const allEmails = getAllAttendeesEmails(events);
  return allEmails.reduce((allAttendees: Record<string, Attendee>, email) => {
    allAttendees[email] = { displayName: email, email };
    return allAttendees;
  }, {});
}

export const validateEmail = (email: string) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const isJSMProject = (project: JiraProject) =>
  project.projectTypeKey === "service_desk";

const isFieldTypeValid = (field: JiraField) =>
  ["number", "string", "date", "datetime"].includes(field.schema?.type);

const isValidForProjectType = (field: JiraField, project: JiraProject) =>
  !(!isJSMProject(project) && field.id === PORTAL_LINK_FIELD.id);

export const validateField = (field: JiraField, project: JiraProject) =>
  isFieldTypeValid(field) && isValidForProjectType(field, project);

export const ISSUE_LINK_FIELD = {
  id: "issueLink",
  name: "Issue link",
  schema: {
    type: "string",
    system: "custom",
  },
};

export const PORTAL_LINK_FIELD = {
  id: "portalLink",
  name: "Portal link",
  schema: {
    type: "string",
    system: "custom",
  },
};

export const getAllDescriptionFields = async () => {
  const allJiraFields = await getIssueFields();
  const customLinkFields = [ISSUE_LINK_FIELD, PORTAL_LINK_FIELD];
  return customLinkFields.concat(allJiraFields);
};

export const getIssueUrl = (hostBaseUrl: string, issue: JiraIssueResponse) =>
  `${hostBaseUrl}/browse/${issue.key}`;

export const getPortalUrl = (
  hostBaseUrl: string,
  serviceDeskId: string,
  issueId: string
) => `${hostBaseUrl}/servicedesk/customer/portal/${serviceDeskId}/${issueId}`;

export const generateDescriptionFields = (
  descriptionFieldsIds: string[],
  issue: JiraIssueResponse,
  issueFields: JiraField[]
) => {
  const renderedFields = Object.keys(issue.renderedFields).reduce(
    (fields, key) => {
      const value = issue.renderedFields[key];
      if (typeof value === "string") {
        fields[key] = stripHtml(value).result;
      }
      return fields;
    },
    {} as Record<string, string>
  );
  return (
    descriptionFieldsIds
      .filter((field) => Boolean(issue.fields?.[field]))
      .map(
        (field) =>
          `${
            issueFields.find((issueField) => issueField.id === field)?.name ||
            field
          }: ${renderedFields[field] || String(issue.fields[field])}`
      )
      .join("\n\n") + "\n\n"
  );
};

export const stripGoogleEventDescription = (description: string) =>
  stripHtml(description, {
    ignoreTags: ["br"],
  }).result.replace(/<br>/g, "\n");

export const getProjectTypeKeyValue = (projectTypeKey: string) => {
  const allowedValues = ["software", "service_desk", "business"];
  const isKeyInAllowedValues = allowedValues.includes(projectTypeKey);
  return isKeyInAllowedValues ? projectTypeKey : "other";
};
