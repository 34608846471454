import React, { ReactNode, useState } from "react";
import styled from "styled-components";
import HipchatChevronUpIcon from "@atlaskit/icon/glyph/hipchat/chevron-up";
import HipchatChevronDownIcon from "@atlaskit/icon/glyph/hipchat/chevron-down";
import { Label } from "./Label";

const ExpandableDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25);
  background-color: rgba(9, 30, 66, 0.02);
`;

const ExpandableHeader = styled.div`
  cursor: pointer;
  padding: 8px;
  gap: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  * {
    cursor: pointer;
  }
`;

const ExpandableContent = styled.div`
  padding: 16px;
  white-space: pre-wrap;
  word-break: break-word;
`;

interface ExpandableCardProps {
  headerText: string;
  body: ReactNode;
}

export const ExpandableCard = ({ headerText, body }: ExpandableCardProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <ExpandableDiv>
      <ExpandableHeader onClick={() => setIsExpanded((prev) => !prev)}>
        <Label>{headerText}</Label>
        {isExpanded ? (
          <HipchatChevronUpIcon label="" />
        ) : (
          <HipchatChevronDownIcon label="" />
        )}
      </ExpandableHeader>
      {isExpanded && <ExpandableContent>{body}</ExpandableContent>}
    </ExpandableDiv>
  );
};
