import React, { useEffect } from "react";
import { fuseAnalyticsClient } from "@appfire/fuse-analytics-client";

interface FuseAnalyticsProps {
  children: React.ReactNode;
}

export function FuseAnalytics({ children }: FuseAnalyticsProps) {
  useEffect(() => {
    fuseAnalyticsClient("Jira", {
      lite: true,
      liteConfig: {
        amplitudeApiKey: process.env.AMPLITUDE_API_KEY, // generated api key from amplitude
        appKey: "google-calendar",
      },
      fingerprintAccessKey: process.env.FINGERPRINT_PUBLIC_API_KEY,
    });
  }, []);

  return <>{children}</>;
}
