import React, {
  Dispatch,
  SetStateAction,
  SyntheticEvent,
  useCallback,
} from "react";
import Button from "@atlaskit/button";
import EmptyState from "@atlaskit/empty-state";
import { Calendar, Event } from "../services/calendar";
import { EventsList } from "../components/EventsList";

interface EventsListTabProps {
  events: Event[];
  setSelectedTab: Dispatch<SetStateAction<number>>;
  setMessage: Dispatch<SetStateAction<JSX.Element | null>>;
  refreshEvents: () => void;
  calendars: Calendar[];
}

export const EventsTab = ({
  events,
  setSelectedTab,
  setMessage,
  refreshEvents,
  calendars,
}: EventsListTabProps) => {
  const switchToCreateTab = useCallback(
    (e: SyntheticEvent) => {
      e.preventDefault();
      setSelectedTab(0);
      setMessage(null);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setSelectedTab]
  );

  return events.length ? (
    <EventsList
      events={events}
      calendars={calendars}
      setMessage={setMessage}
      refreshEventList={refreshEvents}
    />
  ) : (
    <EmptyState
      header="No events has been created"
      description="Navigate to Create Event Tab to get started"
      primaryAction={
        <Button onClick={switchToCreateTab} appearance="primary">
          Create Event
        </Button>
      }
    />
  );
};
