import React, { Dispatch, SetStateAction, useMemo } from "react";
import Button from "@atlaskit/button";
import Lozenge from "@atlaskit/lozenge";
import Tooltip from "@atlaskit/tooltip";
import InfoIcon from "@atlaskit/icon/glyph/info";
import styled from "styled-components";
import { Event } from "../services/calendar";
import {
  getDisplayDate,
  getDisplayDateWithTime,
  stripGoogleEventDescription,
} from "../services/utils";
import { useIssueContext } from "../screens/Providers/IssueContextProvider";
import { useApplicationContext } from "../screens/Providers/ApplicationContextProvider";
import { useAttendeesContext } from "../screens/Providers/AttendeesProvider";
import { getReadableRecurrence } from "../services/recurrence-utils";
import { useCalendarsContext } from "./CalendarsDataProvider";
import { DeleteConfirmation } from "./DeleteConfirmation";
import { ExpandableCard } from "./shared/ExpandableCard";

interface EventsListPreviewProps {
  event: Event;
  removeEvent: () => void;
  editEvent: () => void;
  setMessage: Dispatch<SetStateAction<JSX.Element | null>>;
  isEditable: boolean;
}

export const EventsListPreview = ({
  event,
  removeEvent,
  editEvent,
  setMessage,
  isEditable,
}: EventsListPreviewProps) => {
  const { issue } = useIssueContext();
  const { baseUrl } = useApplicationContext();
  const { calendars } = useCalendarsContext();
  const { attendees } = useAttendeesContext();

  const issueUrl = useMemo(() => {
    return `${baseUrl}/browse/${issue.key}`;
  }, [issue.key, baseUrl]);

  const isPastEvent = event.end.date
    ? new Date(Date.now() - 3600 * 1000 * 24) > new Date(event.end.date)
    : new Date() > new Date(event.end.dateTime as string) || false;

  const eventCalendar = calendars.find((cal) => cal.id === event.calendarId);
  const description = useMemo(() => {
    if (!event.description || issueUrl === event.description) {
      return undefined;
    }
    return stripGoogleEventDescription(event.description);
  }, [issueUrl, event.description]);

  const guestsList = useMemo(
    () =>
      event.attendees
        ? event.attendees
            .map(
              (attendee) =>
                attendees[attendee.email]?.displayName || attendee.email
            )
            .join(", ")
        : "",
    [attendees, event.attendees]
  );

  const hasGuests = Boolean(event.attendees?.length);

  return (
    <EventsListPreview.Wrapper>
      <EventsListPreview.Title>
        <a
          href={event.htmlLink}
          rel="noreferrer"
          target="_blank"
          data-testid={`event-link`}
        >
          {event.summary}
          {isPastEvent && (
            <PastEventBadge>
              <Lozenge>Past Event</Lozenge>
            </PastEventBadge>
          )}
        </a>
      </EventsListPreview.Title>
      <EventProperty
        label="Posted on calendar"
        value={eventCalendar ? eventCalendar.summary : "No Calendar Found"}
      />
      {event.start && (
        <EventProperty
          label="From"
          value={
            event.start.dateTime
              ? getDisplayDateWithTime(
                  event.start.dateTime,
                  eventCalendar?.timeZone
                )
              : getDisplayDate(event.start.date, eventCalendar?.timeZone)
          }
        />
      )}
      {event.end && (
        <EventProperty
          label="To"
          value={
            event.end.dateTime
              ? getDisplayDateWithTime(
                  event.end.dateTime,
                  eventCalendar?.timeZone
                )
              : getDisplayDate(event.end.date, eventCalendar?.timeZone)
          }
        />
      )}
      {event.recurrence && (
        <EventProperty
          label="Repeats"
          value={getReadableRecurrence(event.recurrence[0])}
        />
      )}
      {hasGuests && <EventProperty label="Guests" value={guestsList} />}
      {description && (
        <ExpandableCard headerText="Description" body={description} />
      )}
      <EventsListPreview.Buttons>
        <Button
          isDisabled={!isEditable}
          appearance="primary"
          onClick={() => editEvent()}
          testId={`edit-button`}
        >
          Edit
        </Button>
        <Button
          isDisabled={!isEditable}
          appearance="subtle"
          onClick={() =>
            setMessage(
              <DeleteConfirmation
                event={event}
                removeEvent={removeEvent}
                setMessage={setMessage}
              ></DeleteConfirmation>
            )
          }
          testId="delete-event-button"
        >
          Delete
        </Button>
        {!isEditable && (
          <Tooltip content={"No permission to update this event"}>
            <InfoIcon size="small" label="info" primaryColor="#0747A6" />
          </Tooltip>
        )}
      </EventsListPreview.Buttons>
    </EventsListPreview.Wrapper>
  );
};
const PastEventBadge = styled.span`
  padding-left: 12px;
`;

EventsListPreview.Wrapper = styled.li`
  display: flex;
  flex-direction: column;
  gap: 12px;
  border-top: 1px solid #ccc;
  padding-top: 10px;
  &:first-child {
    border-top: none;
  }
`;

EventsListPreview.Title = styled.h1`
  font-size: 1em;
  font-style: inherit;
  line-height: 1.333;
  font-weight: 600;
  display: inline-block;
  margin-top: 10px !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
`;

EventsListPreview.Buttons = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  button {
    padding-left: 10px;
    padding-right: 10px;
  }
`;

interface EventPropertyProps {
  label: string;
  value: string;
}

const EventProperty = ({ label, value }: EventPropertyProps) => {
  return (
    <EventProperty.Wrapper>
      <EventProperty.Label>{label}</EventProperty.Label>
      <EventProperty.Value>{value}</EventProperty.Value>
    </EventProperty.Wrapper>
  );
};

EventProperty.Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

EventProperty.Label = styled.span`
  font-size: 0.85em;
  font-style: inherit;
  line-height: 1.333;
  color: var(--ds-text-subtlest, var(--ds-text-subtlest, #6b778c));
  font-weight: 600;
  display: inline-block;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
`;

EventProperty.Value = styled.span``;
