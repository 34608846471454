import { getJiraToken } from "./jira-api";
import { ErrorWithStatus } from "./utils";

export interface JSONResponse {
  message: string;
}

export async function authenticatedApiRequest(
  name: string,
  method = "GET",
  body?: unknown
) {
  const token = await getJiraToken();
  const coreRequest = {
    method: method,
    headers: {
      Authorization: `JWT ${token}`,
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(
    `/api${name}`,
    body ? { ...coreRequest, ...{ body: JSON.stringify(body) } } : coreRequest
  );
  if (!response.ok) {
    const { message } = (await response.json()) as JSONResponse;

    return Promise.reject(new ErrorWithStatus(response.status, message));
  } else {
    return response.json();
  }
}
