import styled from "styled-components";
import React, { Dispatch, SetStateAction } from "react";
import { Calendar, Event } from "../services/calendar";
import { EventsListElement } from "./EventsListElement";

interface EventsListProps {
  events: Event[];
  setMessage: Dispatch<SetStateAction<JSX.Element | null>>;
  refreshEventList: () => void;
  calendars: Calendar[];
}

export const EventsList = ({
  events,
  setMessage,
  refreshEventList,
  calendars,
}: EventsListProps) => {
  return (
    <div>
      <EventsList.List>
        {events.map((event) => (
          <EventsListElement
            key={event.eventKey}
            calendars={calendars}
            event={event}
            setMessage={setMessage}
            refreshEventList={refreshEventList}
          />
        ))}
      </EventsList.List>
    </div>
  );
};

EventsList.List = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 10px;
`;
