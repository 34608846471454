import React, {
  useState,
  createContext,
  useEffect,
  useContext,
  useMemo,
} from "react";
import { JiraProject } from "../../types";
import { LoadingPage } from "../../components/LoadingPage";
import { ServerErrorMessage } from "../../components/messages/ServerErrorMessage";
import { getProject, getProjectServiceDeskId } from "../../services/jira-api";
import { isJSMProject } from "../../services/utils";
import { useApplicationContext } from "./ApplicationContextProvider";

interface ProjectContextData {
  project: JiraProject;
  serviceDeskId?: string;
}

const ProjectContext = createContext<ProjectContextData | null>(null);

interface ProjectDataProviderProps {
  children: React.ReactNode;
}

export const ProjectContextProvider = ({
  children,
}: ProjectDataProviderProps) => {
  const { jira } = useApplicationContext();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [project, setProject] = useState<JiraProject>();
  const [serviceDeskId, setServiceDeskId] = useState<string>();
  useEffect(() => {
    void (async () => {
      try {
        const fetchedProject = await getProject(jira.project.id);
        setProject(fetchedProject);
        if (isJSMProject(fetchedProject)) {
          const serviceDesk = await getProjectServiceDeskId(jira.project.id);
          setServiceDeskId(serviceDesk.id);
        }
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [jira.project.id]);

  const context = useMemo(
    () => ({
      project,
      serviceDeskId,
    }),
    [project, serviceDeskId]
  );

  if (isLoading) {
    return <LoadingPage />;
  }
  if (!context) {
    return <ServerErrorMessage />;
  }
  return (
    <ProjectContext.Provider value={context as ProjectContextData}>
      {children}
    </ProjectContext.Provider>
  );
};

export function useProjectContext() {
  const context = useContext(ProjectContext);
  if (!context) {
    throw new Error(
      "Project context not initialized, probably context provider is missing"
    );
  }
  return context;
}
