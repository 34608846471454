import React, { useState, Dispatch, SetStateAction } from "react";
import Spinner from "@atlaskit/spinner";
import { SectionMessageAction } from "@atlaskit/section-message";
import { Event } from "../services/calendar";
import { DefaultSectionMessage } from "./messages/DefaultSectionMessage";

interface EventsListPreviewProps {
  event: Event;
  removeEvent: () => void;
  setMessage: Dispatch<SetStateAction<JSX.Element | null>>;
}

export const DeleteConfirmation = ({
  event,
  removeEvent,
  setMessage,
}: EventsListPreviewProps) => {
  const [deleteStarted, setDeleteStarted] = useState(false);

  return (
    <DefaultSectionMessage
      actions={
        deleteStarted
          ? [
              <SectionMessageAction key="loading">
                <Spinner />
              </SectionMessageAction>,
            ]
          : [
              <SectionMessageAction
                key="delete"
                onClick={() => {
                  setDeleteStarted(true);
                  removeEvent();
                }}
                testId="confirm-deletion-button"
              >
                Confirm
              </SectionMessageAction>,
              <SectionMessageAction
                key="cancel"
                onClick={() => setMessage(null)}
              >
                Cancel
              </SectionMessageAction>,
            ]
      }
      appearance="warning"
      title="Confirm deletion"
    >
      <p>
        Are you sure if you want to delete event{" "}
        <strong>{event.summary}</strong> from your calendar ?
      </p>
    </DefaultSectionMessage>
  );
};
