import { addDays, format } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";

const DATE_FORMAT_STRING = "yyyy-MM-dd";

function addOneDay(date: string, timeZone?: string) {
  const plusOneDay = addDays(new Date(date), 1);
  return timeZone
    ? formatInTimeZone(plusOneDay, timeZone, DATE_FORMAT_STRING)
    : format(plusOneDay, DATE_FORMAT_STRING);
}

function subtractOneDay(date: string, timeZone?: string) {
  const minusOneDay = addDays(new Date(date), -1);
  return timeZone
    ? formatInTimeZone(minusOneDay, timeZone, DATE_FORMAT_STRING)
    : format(minusOneDay, DATE_FORMAT_STRING);
}

export function prepareEndDate(
  date: string,
  isAllDayOn: boolean,
  timeZone?: string
) {
  return isAllDayOn ? addOneDay(date, timeZone) : date;
}

export function parseEndDate(
  date: string,
  isAllDayOn: boolean,
  timeZone?: string
) {
  return isAllDayOn ? subtractOneDay(date, timeZone) : date;
}
