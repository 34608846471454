import { useMemo, useState } from "react";
import { Options } from "rrule";
import {
  generateMonthlyRecurrenceOptions,
  RecurrenceOption,
  rrulePropertiesEquals,
} from "../../services/recurrence-utils";

interface UseMonthlyRecurrencePresets {
  fromDate: string;
  rrule: Partial<Options>;
}

export default function useMonthlyRecurrencePresets({
  fromDate,
  rrule,
}: UseMonthlyRecurrencePresets) {
  const monthlyRecurrencePresets = useMemo(
    () => generateMonthlyRecurrenceOptions(new Date(fromDate), rrule.interval),
    [rrule, fromDate]
  );

  const selectedMonthlyRecurrencePreset = findMonthlyRecurrenceOption(
    rrule,
    monthlyRecurrencePresets
  );

  const [defaultMonthlyRecurrencePreset] = monthlyRecurrencePresets;

  const [monthlyRecurrencePreset, setMonthlyRecurrencePreset] = useState(
    selectedMonthlyRecurrencePreset || defaultMonthlyRecurrencePreset
  );

  function refreshMonthlyRecurrencePreset(interval: number) {
    const update = findMonthlyRecurrenceOption(
      monthlyRecurrencePreset.value,
      generateMonthlyRecurrenceOptions(new Date(fromDate), interval)
    );
    if (update) {
      setMonthlyRecurrencePreset(update);
    }
  }

  return {
    monthlyRecurrencePreset,
    defaultMonthlyRecurrencePreset,
    setMonthlyRecurrencePreset,
    monthlyRecurrencePresets,
    refreshMonthlyRecurrencePreset,
  };
}

function findMonthlyRecurrenceOption(
  rrule: Partial<Options>,
  options: RecurrenceOption[]
) {
  return options.find((option) => {
    return rrulePropertiesEquals(rrule, option.value, [
      "count",
      "until",
      "interval",
    ]);
  });
}
