import { Options, RRule, rrulestr, Weekday, WeekdayStr } from "rrule";
import { OptionType } from "@atlaskit/select";
import { prepareGoogleDate } from "./utils";

export type DayOfWeek = 0 | 1 | 2 | 3 | 4 | 5 | 6;

export interface RecurrencePresetOption extends Omit<OptionType, "value"> {
  value: string | undefined;
}

export const generateRecurrencePresetOptions = (
  fromDate: string,
  fromTime: string | undefined,
  timeZone: string | undefined,
  recurrence: string | undefined
): RecurrencePresetOption[] => {
  const date = getSelectedDate(fromDate, fromTime, timeZone);
  const weekdayCode = getWeekdayCode(date);

  const everyDay = new RRule({
    freq: RRule.DAILY,
    interval: 1,
  });

  const everyWeek = new RRule({
    freq: RRule.WEEKLY,
    interval: 1,
    byweekday: [RRule[weekdayCode]],
  });

  const everyMonth = new RRule({
    freq: RRule.MONTHLY,
    interval: 1,
    byweekday: [RRule[weekdayCode].nth(getOccurrence(date))],
  });

  const everyYear = new RRule({
    freq: RRule.YEARLY,
    interval: 1,
    bymonth: date.getMonth() + 1,
    bymonthday: date.getDate(),
  });

  const everyWeekday = new RRule({
    freq: RRule.WEEKLY,
    interval: 1,
    byweekday: [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR],
  });

  const options = [
    { label: "does not repeat", value: undefined },
    {
      label: everyDay.toText(),
      value: everyDay.toString(),
    },
    {
      label: everyWeek.toText(),
      value: everyWeek.toString(),
    },
    {
      label: everyMonth.toText(),
      value: everyMonth.toString(),
    },
    {
      label: everyYear.toText(),
      value: everyYear.toString(),
    },
    {
      label: everyWeekday.toText(),
      value: everyWeekday.toString(),
    },
  ];

  const isAlreadyAdded =
    recurrence &&
    options
      .map(({ value }) => {
        return (
          value &&
          rrulePropertiesEquals(
            recurrenceToRruleProperties(value),
            recurrenceToRruleProperties(recurrence)
          )
        );
      })
      .some(Boolean);

  if (recurrence && !isAlreadyAdded) {
    options.push({
      label: `${rrulestr(recurrence).toText()} (custom)`,
      value: `${rrulestr(recurrence).toString()}`,
    });
  }

  options.push({
    label: "Custom...",
    value: "custom",
  });

  return options.map(({ label, value }) => ({
    label: startWithUpperCase(label),
    value,
  }));
};

function getSelectedDate(
  fromDate: string,
  fromTime?: string,
  timeZone?: string
) {
  const { dateTime, date } = prepareGoogleDate(fromDate, timeZone, fromTime);
  return (
    (dateTime && new Date(dateTime)) || (date && new Date(date)) || new Date()
  );
}

function getWeekdayCode(date: Date) {
  const dayName = date.toLocaleString("en-US", { weekday: "long" });
  return dayName.substring(0, 2).toUpperCase() as WeekdayStr;
}

function getOccurrence(date: Date) {
  const day = date.getDate();
  const month = date.getMonth();
  const year = date.getFullYear();
  const firstOfMonth = new Date(year, month, 1);
  const firstDayOfWeek = firstOfMonth.getDay();
  const adjustedDay = day + firstDayOfWeek - 1;
  return Math.floor(adjustedDay / 7) + 1;
}

const startWithUpperCase = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1);

export function getReadableRecurrence(recurrence: string) {
  const text = rrulestr(recurrence).toText();
  return startWithUpperCase(text);
}

export interface RecurrenceOption extends Omit<OptionType, "value"> {
  value: Partial<Options>;
}

export function generateMonthlyRecurrenceOptions(
  date: Date,
  interval: number | undefined
): RecurrenceOption[] {
  const everyMonthOnThisDate = new RRule({
    freq: RRule.MONTHLY,
    interval,
  });

  const weekdayCode = getWeekdayCode(date);

  const everyMonthOnThisOccurrence = new RRule({
    freq: RRule.MONTHLY,
    byweekday: [RRule[weekdayCode].nth(getOccurrence(date))],
    interval,
  });

  return [
    {
      label: startWithUpperCase(
        `${everyMonthOnThisDate.toText()} on day ${date.getDate()}`
      ),
      value: {
        freq: RRule.MONTHLY,
        interval,
      },
    },
    {
      label: startWithUpperCase(everyMonthOnThisOccurrence.toText()),
      value: {
        freq: RRule.MONTHLY,
        byweekday: [RRule[weekdayCode].nth(getOccurrence(date))],
        interval,
      },
    },
  ];
}

export function findRecurrencePresetByValue(
  presetValue: string | undefined,
  presets: RecurrencePresetOption[]
) {
  if (!presetValue) {
    return presets[0];
  }

  const find = presets.find((preset) => {
    return (
      preset.value &&
      preset.value !== "custom" &&
      presetValue &&
      presetValue !== "custom" &&
      rrulePropertiesEquals(
        recurrenceToRruleProperties(preset.value),
        recurrenceToRruleProperties(presetValue)
      )
    );
  });

  return find || presets.find((preset) => preset?.value === "custom");
}

export function rrulePropertiesToRecurrence(options: Partial<Options>) {
  const { freq, byweekday, interval, until, count } = options;
  return new RRule({
    freq,
    byweekday,
    interval,
    until,
    count,
  }).toString();
}

export function recurrenceToRruleProperties(recurrence: string) {
  const {
    options: { freq, byweekday, bynweekday, interval, until, count },
  } = rrulestr(recurrence);

  return {
    freq,
    byweekday: byweekday || toByWeekday(bynweekday),
    interval,
    until,
    count,
  };
}

function toByWeekday(bynweekday: number[][] | null) {
  if (!bynweekday) {
    return undefined;
  }
  return [new Weekday(bynweekday[0][0], bynweekday[0][1])];
}

export function rrulePropertiesEquals(
  a: Partial<Options>,
  b: Partial<Options>,
  skip?: (keyof Partial<Options>)[]
) {
  type Key = keyof Partial<Options>;
  const keys: Key[] = Array.from(
    new Set([...(Object.keys(a) as Key[]), ...(Object.keys(b) as Key[])])
  );
  for (const key of keys) {
    if (!skip?.includes(key) && a[key]?.toString() !== b[key]?.toString()) {
      return false;
    }
  }
  return true;
}
