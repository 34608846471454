import SectionMessage, {
  SectionMessageAction,
  Appearance,
} from "@atlaskit/section-message";
import React from "react";
import styled from "styled-components";

interface DefaultSectionMessageProps {
  onClose?: () => void;
  title?: string;
  appearance: Appearance;
  actions?: JSX.Element[];
  children?: React.ReactNode;
}

const MessageWrapper = styled.div`
  margin-top: 16px;
`;

export function DefaultSectionMessage({
  onClose,
  title,
  appearance,
  actions,
  children,
}: DefaultSectionMessageProps) {
  return (
    <MessageWrapper>
      <SectionMessage
        appearance={appearance}
        title={title}
        actions={
          actions || [
            <SectionMessageAction key="close" onClick={onClose}>
              Close
            </SectionMessageAction>,
          ]
        }
      >
        {children}
      </SectionMessage>
    </MessageWrapper>
  );
}
