import { FetchedAttendee } from "../types";
import { authenticatedApiRequest, JSONResponse } from "./api";

export interface AttendeeSubmitData {
  email?: string;
  accountId?: string;
}

export interface FormData {
  summary: string;
  fromDate: string;
  toDate: string;
  fromTime: string | undefined;
  toTime: string | undefined;
  description: string;
  allDay: boolean;
  timeZone?: string;
  recurrence?: string;
  calendarId?: string;
  attendees: AttendeeSubmitData[];
}

export type GoogleDateTimeFormat = {
  date?: string;
  dateTime?: string;
  timeZone?: string;
};

export interface NewEvent {
  summary: string;
  end: GoogleDateTimeFormat;
  start: GoogleDateTimeFormat;
  description?: string;
  attendees: AttendeeSubmitData[];
  recurrence?: [string];
}

export interface Organizer {
  displayName: string;
  email: string;
  self: boolean;
}

export interface Event extends Omit<NewEvent, "attendees"> {
  htmlLink: string;
  organizer: Organizer;
  eventKey: string;
  eventId: string;
  calendarId: string;
  allDay: boolean;
  attendees: FetchedAttendee[];
}

export interface Calendar {
  id: string;
  summary: string;
  timeZone: string;
  primary: boolean;
  accessRole: string;
}

interface CreateEventJSONResponse extends JSONResponse {
  data: {
    htmlLink: string;
    id: string;
  };
}

interface GetEventsProps {
  issueKey: string;
}

export interface EventJSONResponse extends JSONResponse {
  id: string;
  summary: string;
  start: GoogleDateTimeFormat;
  end: GoogleDateTimeFormat;
  description: string;
  htmlLink: string;
  organizer: Organizer;
}

export async function getCalendars() {
  return authenticatedApiRequest("/calendars");
}

export async function getEvents({
  issueKey,
}: GetEventsProps): Promise<Event[]> {
  let fetchedEvents: Event[] = [];

  try {
    const url = `/events?issueKey=${issueKey}`;
    fetchedEvents = (await authenticatedApiRequest(url)) as Event[];

    return fetchedEvents.sort((a, b) => {
      const endTimeA = a.end.dateTime
        ? new Date(a.end.dateTime)
        : new Date(a.end.date as string);
      const endTimeB = b.end.dateTime
        ? new Date(b.end.dateTime)
        : new Date(b.end.date as string);

      if (endTimeA < endTimeB) {
        return 1;
      } else if (endTimeA > endTimeB) {
        return -1;
      }
      return 0;
    });
  } catch (error) {}

  return fetchedEvents;
}

export async function createEvent(
  event: NewEvent,
  calendarId: string,
  issueKey: string
) {
  const response = (await authenticatedApiRequest(
    `/calendar/${calendarId}/events`,
    "POST",
    {
      event,
      issueKey,
    }
  )) as CreateEventJSONResponse;

  const { data } = response;
  return {
    calendarId,
    eventId: data.id,
  };
}

export async function updateEvent(
  event: NewEvent,
  calendarId: string,
  eventId: string,
  issueKey: string
) {
  const { data } = (await authenticatedApiRequest(
    `/calendar/${calendarId}/events`,
    "PATCH",
    {
      event,
      eventId,
      issueKey,
    }
  )) as CreateEventJSONResponse;

  return {
    calendarId,
    eventId: data.id,
  };
}

export async function deleteEvent(
  calendarId: string,
  eventId: string,
  eventKey: string,
  issueKey: string
) {
  await authenticatedApiRequest(`/calendar/${calendarId}/events`, "DELETE", {
    issueKey,
    eventId,
    eventKey,
  });

  return {
    calendarId,
  };
}

export function getPrimaryCalendarId(calendars: Calendar[]) {
  return calendars.find((calendar) => calendar.primary)?.id;
}

export function getCalendarById(calendars: Calendar[], id: string) {
  return calendars.find((calendar) => calendar.id === id);
}
