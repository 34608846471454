import React from "react";
import { SectionMessageAction } from "@atlaskit/section-message";
import { useFeedbackContext } from "../FeedbackProvider";
import { DefaultSectionMessage } from "./DefaultSectionMessage";

export function ServerErrorMessage() {
  const { setIsFeedbackOpen } = useFeedbackContext();
  return (
    <DefaultSectionMessage
      appearance="error"
      title="Error occurred"
      actions={[
        <SectionMessageAction
          onClick={() => window.location.reload()}
          key="reload"
        >
          Reload app
        </SectionMessageAction>,
        <SectionMessageAction
          onClick={() => setIsFeedbackOpen(true)}
          key="support"
        >
          Support
        </SectionMessageAction>,
      ]}
    >
      <p>The server encountered an error processing the request.</p>
    </DefaultSectionMessage>
  );
}
