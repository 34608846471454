import Checkbox from "@atlaskit/checkbox";
import { CheckboxField, Field } from "@atlaskit/form";
import TextArea from "@atlaskit/textarea";
import React from "react";
import styled from "styled-components";
import EditorPanelIcon from "@atlaskit/icon/glyph/editor/panel";
import Tooltip from "@atlaskit/tooltip";
import { useDescriptionFieldsContext } from "../screens/Providers/DescriptionFieldsContextProvider";
import { Label } from "./shared/Label";
import { CheckboxRow } from "./EventForm";

const DescriptionWrapper = styled.div`
  margin-top: 8px;
`;

const InfoTooltipWrapper = styled.div`
  margin-top: 8px;
  div {
    display: flex;
    align-items: center;
  }
`;

interface DescriptionFormFieldProps {
  isCustomDescriptionSelected: boolean;
  onCustomDescriptionSelect: () => void;
  customDescriptionValue: string;
  areDescriptionFieldsEnabled: boolean;
  onDescriptionFieldsEnabledChange: () => void;
  enableAutomationCheckboxes: boolean;
}

export const DescriptionFormField = ({
  isCustomDescriptionSelected,
  onCustomDescriptionSelect,
  customDescriptionValue,
  areDescriptionFieldsEnabled,
  onDescriptionFieldsEnabledChange,
  enableAutomationCheckboxes,
}: DescriptionFormFieldProps) => {
  const { descriptionFieldsIds, issueFields } = useDescriptionFieldsContext();

  const fieldsNames = descriptionFieldsIds
    .map(
      (fieldId) =>
        issueFields.find((issueField) => issueField.id === fieldId)?.name ||
        fieldId
    )
    .join("; ");

  const tooltipText = `Custom fields configured in Settings: ${
    fieldsNames || "empty"
  }`;

  return (
    <DescriptionWrapper>
      <Label>Event description</Label>
      {enableAutomationCheckboxes && (
        <>
          <CheckboxRow>
            <CheckboxField name="areDescriptionFieldsEnabled">
              {({ fieldProps }) => (
                <Checkbox
                  {...fieldProps}
                  isChecked={areDescriptionFieldsEnabled}
                  label="Add custom fields to description"
                  onChange={onDescriptionFieldsEnabledChange}
                />
              )}
            </CheckboxField>
            <InfoTooltipWrapper>
              <Tooltip content={tooltipText}>
                <EditorPanelIcon label="" />
              </Tooltip>
            </InfoTooltipWrapper>
          </CheckboxRow>
          <CheckboxRow>
            <CheckboxField name="isCustomDescription">
              {({ fieldProps }) => (
                <Checkbox
                  {...fieldProps}
                  isChecked={isCustomDescriptionSelected}
                  label="Add custom description"
                  onChange={onCustomDescriptionSelect}
                />
              )}
            </CheckboxField>
          </CheckboxRow>
        </>
      )}
      {isCustomDescriptionSelected && (
        <Field name="description" defaultValue={customDescriptionValue}>
          {({ fieldProps }: any) => (
            <TextArea
              {...fieldProps}
              placeholder="Type something..."
              minimumRows={5}
            />
          )}
        </Field>
      )}
    </DescriptionWrapper>
  );
};
