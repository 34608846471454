import React, { useEffect, useState } from "react";
import {
  AuthProvider,
  useFirebaseApp,
  DatabaseProvider,
  FirebaseAppProvider,
  useAuth,
} from "reactfire";

import {
  Auth,
  getAuth,
  signInWithCustomToken,
  setPersistence,
  browserSessionPersistence,
  inMemoryPersistence,
  onAuthStateChanged,
} from "firebase/auth";
import { getDatabase } from "firebase/database";
import { LoadingPage } from "../components/LoadingPage";
import { useApplicationContext } from "../screens/Providers/ApplicationContextProvider";
import { getErrorMessage } from "../services/utils";
import { FatalErrorMessage } from "../components/ErrorBoundary";

const config = {
  apiKey: process.env.API_KEY,
  authDomain: process.env.AUTH_DOMAIN,
  databaseURL: process.env.DATABASE_URL,
  projectId: process.env.FIREBASE_PROJECT_ID,
  persistence: browserSessionPersistence,
};

interface FirebaseContextProps {
  children?: React.ReactNode;
}

async function signIn(auth: Auth, token: string) {
  await setPersistence(auth, inMemoryPersistence);
  await signInWithCustomToken(auth, token);
  try {
    await setPersistence(auth, browserSessionPersistence);
  } catch (error) {
    console.warn(
      "Failed to use session storage for firebase auth persistence",
      error
    );
  }
}

function FirebaseServiceProvider({ children }: FirebaseContextProps) {
  const app = useFirebaseApp();
  const auth = getAuth(app);
  const database = getDatabase(app);
  return (
    <DatabaseProvider sdk={database}>
      <AuthProvider sdk={auth}>{children}</AuthProvider>
    </DatabaseProvider>
  );
}

function FirebaseAuthProvider({ children }: FirebaseContextProps) {
  const auth = useAuth();
  const { firebaseToken, userAccountId } = useApplicationContext();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    async function signInWithToken() {
      try {
        await signIn(auth, firebaseToken);
      } catch (e) {
        let errorMessage = getErrorMessage(e);
        const isThirdPartyCookiesError =
          errorMessage === "Firebase: Error (auth/network-request-failed).";
        if (isThirdPartyCookiesError) {
          errorMessage =
            "In order for our application to function properly, you must have third-party cookies enabled on your browser.";
        }
        setError(errorMessage);
      } finally {
        setIsLoading(false);
      }
    }

    onAuthStateChanged(auth, (user) => {
      const tokenUserId = user?.uid.split(":")[0];
      if (!user || tokenUserId !== userAccountId) {
        void signInWithToken();
      } else {
        setIsLoading(false);
      }
    });
  }, [auth]);

  if (error) {
    return <FatalErrorMessage errorMessage={error} />;
  } else {
    return <>{isLoading ? <LoadingPage /> : children}</>;
  }
}

export function FirebaseProvider({ children }: FirebaseContextProps) {
  return (
    <FirebaseAppProvider firebaseConfig={config}>
      <FirebaseServiceProvider>
        <FirebaseAuthProvider>{children}</FirebaseAuthProvider>
      </FirebaseServiceProvider>
    </FirebaseAppProvider>
  );
}
