import { JSONResponse, OauthResponse } from "../types";
import { getJiraToken } from "./jira-api";

function hasRequiredScopes(scopes: string, scope: string) {
  return scopes.split(" ").every((s) => scope?.includes(s));
}

export async function authorize(res: OauthResponse, scopes: string) {
  if (res.error === "access_denied") {
    return Promise.reject(
      new Error("We were unable to connect to your google account.")
    );
  }
  if (!hasRequiredScopes(scopes, res.scope)) {
    return Promise.reject(
      new Error(
        "Authorization failed, please try again and grant access to all scopes"
      )
    );
  }
  const token = await getJiraToken();
  const response = await fetch("/api/oauth2callback", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XmlHttpRequest",
      Authorization: `JWT ${token}`,
    },
    body: JSON.stringify({ ...res }),
  });
  if (!response.ok) {
    const { message } = (await response.json()) as JSONResponse;

    return Promise.reject(new Error(message));
  }
}

export async function revokeApplicationAccess() {
  const token = await getJiraToken();
  const response = await fetch("/api/revoke", {
    method: "POST",
    headers: {
      Authorization: `JWT ${token}`,
    },
  });
  if (!response.ok) {
    const { message } = (await response.json()) as JSONResponse;

    return Promise.reject(new Error(message));
  }
}
