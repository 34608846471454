import Select from "@atlaskit/select";
import { Field } from "@atlaskit/form";
import React from "react";
import {
  findRecurrencePresetByValue,
  RecurrencePresetOption,
} from "../../services/recurrence-utils";

interface RecurrenceSelectProps {
  recurrence: string | undefined;
  options: RecurrencePresetOption[];
  selected: RecurrencePresetOption | undefined;
  onRecurrencePresetChanged: (
    preset: RecurrencePresetOption | undefined
  ) => void;
  onRecurrenceSelected: (repeat: string | undefined) => void;
  onCustomRecurrenceDialogOpened: () => void;
}

export function RecurrenceSelect({
  recurrence,
  options,
  selected,
  onRecurrencePresetChanged,
  onRecurrenceSelected,
  onCustomRecurrenceDialogOpened,
}: RecurrenceSelectProps) {
  const selectedOption = findRecurrencePresetByValue(
    recurrence || selected?.value,
    options
  );

  return (
    <Field<string> name="recurrence" id="recurrence">
      {() => (
        <Select
          isDisabled={!options.length}
          options={options}
          value={selectedOption}
          onChange={(option) => {
            onRecurrencePresetChanged(option || undefined);
            if (option?.value === "custom") {
              onCustomRecurrenceDialogOpened();
            } else {
              onRecurrenceSelected(option?.value);
            }
          }}
        />
      )}
    </Field>
  );
}
