import React from "react";
import SectionMessage from "@atlaskit/section-message";
import { getIframeUrlParameterByName } from "../services/utils";
import { useApplicationContext } from "../screens/Providers/ApplicationContextProvider";

interface LicenseCheckerProps {
  children: React.ReactNode;
}

export function LicenseChecker({ children }: LicenseCheckerProps) {
  const isLicenseValid = getIframeUrlParameterByName("lic") === "active";
  const { baseUrl } = useApplicationContext();
  const umpLink = `${baseUrl}/plugins/servlet/upm#manage/google-calendar`;

  if (isLicenseValid) {
    return <>{children}</>;
  } else {
    return (
      <SectionMessage appearance="error" title="Invalid license">
        <div>
          We can’t validate your Issue Events: Google Calendar integration for
          Jira app license. Please check
          <a href={umpLink} target="_top">
            <strong> Manage apps </strong>
          </a>
          screen if the license key is correct. <br />
          If you do not have a license yet you can buy one or start a free 30
          day trial. <br />
          <br />
        </div>
      </SectionMessage>
    );
  }
}
