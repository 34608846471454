import React, {
  useState,
  createContext,
  useEffect,
  useContext,
  useMemo,
} from "react";
import { ApplicationContext, JiraContext } from "../../types";
import { getApplicationContext } from "../../services/context";
import { LoadingPage } from "../../components/LoadingPage";
import { ServerErrorMessage } from "../../components/messages/ServerErrorMessage";
import { getContext } from "../../services/jira-api";

interface Context extends ApplicationContext, JiraContext {}

const ApplicationContext = createContext<Context | null>(null);

interface IssueDataProviderProps {
  children?: React.ReactNode;
}

export const ApplicationContextProvider = ({
  children,
}: IssueDataProviderProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [appContext, setAppContext] = useState<ApplicationContext>();
  const [jiraContext, setJiraContext] = useState<JiraContext>();
  useEffect(() => {
    void (async () => {
      try {
        const [appContext, jiraContext] = (await Promise.all([
          getApplicationContext(),
          getContext(),
        ])) as [ApplicationContext, JiraContext];
        setAppContext(appContext);
        setJiraContext(jiraContext);
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  const context = useMemo(
    () => ({
      ...jiraContext,
      ...appContext,
      baseUrl: appContext?.displayUrl ?? appContext?.hostBaseUrl,
    }),
    [appContext, jiraContext]
  );

  if (isLoading) {
    return <LoadingPage />;
  }
  if (!appContext || !jiraContext) {
    return <ServerErrorMessage />;
  }
  return (
    <ApplicationContext.Provider value={context as Context}>
      {children}
    </ApplicationContext.Provider>
  );
};

export function useApplicationContext() {
  const context = useContext(ApplicationContext);
  if (!context) {
    throw new Error(
      "Issue context not initialized, probably context provider is missing"
    );
  }
  return context;
}
