import React, { useEffect, PropsWithChildren } from "react";
import useResizeObserver from "use-resize-observer";

export const MINIMUM_HEIGHT = 120;
export const MARGIN = 10;

export function ResizeContext({ children }: PropsWithChildren<unknown>) {
  const { ref, height = 1 } = useResizeObserver<HTMLDivElement>();

  useEffect(() => {
    if (window.AP) {
      const currentHeight = height + MARGIN;
      const desiredHeight =
        currentHeight < MINIMUM_HEIGHT ? MINIMUM_HEIGHT : currentHeight;
      window.AP.resize("100%", `${desiredHeight}px`);
    }
  }, [height]);

  return <div ref={ref}>{children}</div>;
}
