import { ApplicationContext, JSONResponse } from "../types";
import { getJiraToken } from "./jira-api";

export async function getApplicationContext(): Promise<ApplicationContext> {
  const token = await getJiraToken();
  const response = await fetch("/api/context", {
    method: "GET",
    headers: {
      Authorization: `JWT ${token}`,
    },
  });
  if (!response.ok) {
    const { message } = (await response.json()) as JSONResponse;

    return Promise.reject(new Error(message));
  }

  return (await response.json()) as ApplicationContext;
}
