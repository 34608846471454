import React, { useState, FormEvent, useCallback } from "react";
import Form, { Field, FormFooter, FormSection } from "@atlaskit/form";
import ArrowLeftCircleIcon from "@atlaskit/icon/glyph/arrow-left-circle";
import TextArea from "@atlaskit/textarea";
import styled from "styled-components";
import TextField from "@atlaskit/textfield";
import Button, { ButtonGroup, LoadingButton } from "@atlaskit/button";
import SectionMessage from "@atlaskit/section-message";
import {
  DOCUMENTATION,
  PRIVACY_POLICY,
  SUPPORT_EMAIL,
} from "../services/variables";
import { sendFeedback } from "../services/feedback";

interface FormData {
  description: string;
  fullName: string;
  email: string;
}

interface SendFeedbackResponse {
  issueKey: string | undefined;
}

const Container = styled.div`
  padding: 8px;
`;

const InnerForm = styled.div`
  width: 70%;
`;

const PrivacyPolicyInfo = styled.div`
  margin-top: 12px;
  color: #707070;
  font-size: 12px;
`;

const ErrorSectionMessage = styled.div`
  margin-top: 12px;
`;

interface FeebackFormProps {
  onClose: () => void;
}

export const FeedbackForm = ({ onClose }: FeebackFormProps) => {
  const [description, setDescription] = useState<string>("");
  const [error, setError] = useState<boolean>(false);

  const isButtonDisabled = useCallback(
    () => !description.length,
    [description]
  );

  const submitData = async ({ fullName, description }: FormData) => {
    try {
      const { issueKey } = (await sendFeedback(
        fullName,
        description
      )) as SendFeedbackResponse;
      AP.flag.create({
        title: "Thank you for your feedback!",
        body: issueKey
          ? "We'll get back to you within 24 hours on working days."
          : "",
        type: "success",
      });
      onClose();
    } catch (err) {
      console.error(err);
      setError(true);
    }
  };

  return (
    <Container>
      <h3>Problems or suggestions?</h3>
      <PrivacyPolicyInfo>
        Check our{" "}
        <a href={DOCUMENTATION} target="_blank" rel="noreferrer">
          User Guide
        </a>{" "}
        for usage instructions.
      </PrivacyPolicyInfo>
      {error && (
        <ErrorSectionMessage>
          <SectionMessage appearance="error">
            An error occurred, and the feedback has not been sent. Please try
            again later or contact us at{" "}
            <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>.
          </SectionMessage>
        </ErrorSectionMessage>
      )}
      <Form<FormData> onSubmit={submitData}>
        {({ formProps, submitting }) => (
          <form {...formProps}>
            <FormSection>
              <Field
                aria-required={true}
                name="description"
                label="Please describe:"
                defaultValue={description}
                isRequired
              >
                {({ fieldProps }: any) => (
                  <TextArea
                    {...fieldProps}
                    minimumRows={4}
                    value={description}
                    onChange={(event: FormEvent<HTMLInputElement>) => {
                      const target = event.target as HTMLInputElement;
                      setDescription(target.value);
                    }}
                  />
                )}
              </Field>
              <InnerForm>
                <Field
                  aria-required={false}
                  name="fullName"
                  label="Your name:"
                  defaultValue=""
                >
                  {({ fieldProps }) => <TextField {...fieldProps} />}
                </Field>
              </InnerForm>
              <PrivacyPolicyInfo>
                All communication with us is subject to our{" "}
                <a href={PRIVACY_POLICY} target="_blank" rel="noreferrer">
                  Privacy Policy
                </a>
                . If you don&apos;t agree with our data policies, please do not
                submit your data.
              </PrivacyPolicyInfo>
            </FormSection>
            <FormFooter align="start">
              <ButtonGroup>
                <Button
                  appearance="subtle"
                  iconBefore={<ArrowLeftCircleIcon label="Back" />}
                  onClick={onClose}
                >
                  Back
                </Button>
                <LoadingButton
                  type="submit"
                  appearance="primary"
                  isLoading={submitting}
                  isDisabled={isButtonDisabled()}
                >
                  Send
                </LoadingButton>
              </ButtonGroup>
            </FormFooter>
          </form>
        )}
      </Form>
    </Container>
  );
};
