import React, { PropsWithChildren } from "react";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import SectionMessage, {
  SectionMessageAction,
} from "@atlaskit/section-message";
import { getApplicationContext } from "../services/context";
import { SUPPORT_URL } from "../services/variables";

Bugsnag.start({
  apiKey: process.env.BUGSNAG_API_KEY as string,
  appType: "client",
  plugins: [new BugsnagPluginReact()],
  enabledReleaseStages: ["production", "staging"],
  releaseStage: process.env.ENVIRONMENT,
  onError: async (event) => {
    try {
      const { clientKey, userAccountId } = await getApplicationContext();
      event.setUser(userAccountId);
      event.addMetadata("login", {
        clientKey,
        userAccountId,
      });
    } catch (err) {
      console.error("Failed to get application context", err);
    }
    return true;
  },
});

const bugsnagReactPlugin = Bugsnag.getPlugin("react");

if (!bugsnagReactPlugin) {
  throw new Error("Bugsnag React plugin failed to load");
}

const BugsnagErrorBoundary = bugsnagReactPlugin.createErrorBoundary(React);

export function ErrorBoundary({ children }: PropsWithChildren<unknown>) {
  return (
    <BugsnagErrorBoundary FallbackComponent={ErrorView}>
      {children}
    </BugsnagErrorBoundary>
  );
}

function ErrorView({ error }: { error: Error }) {
  const errorMessage = error.message;
  return <FatalErrorMessage errorMessage={errorMessage} />;
}

interface FatalErrorMessageProps {
  errorMessage: string;
}

export function FatalErrorMessage({ errorMessage }: FatalErrorMessageProps) {
  const openSupportPortal = () =>
    window.open(SUPPORT_URL, "_blank", "noopener");
  const refreshPage = () => window.location.reload();
  return (
    <SectionMessage
      title="Something went wrong."
      appearance="error"
      actions={[
        <SectionMessageAction key="1" onClick={openSupportPortal}>
          Contact support
        </SectionMessageAction>,
        <SectionMessageAction key="2" onClick={refreshPage}>
          Reload app
        </SectionMessageAction>,
      ]}
    >
      <p>{errorMessage}</p>
    </SectionMessage>
  );
}
