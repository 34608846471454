import { getJiraToken } from "./jira-api";

export async function sendFeedback(fullName: string, description: string) {
  const token = await getJiraToken();
  const response = await fetch(`/api/feedback`, {
    method: "POST",
    headers: {
      Authorization: `JWT ${token}`,
    },
    body: JSON.stringify({
      description,
      fullName,
    }),
  });
  if (!response.ok) {
    return Promise.reject(new Error("Failed to send feedback!"));
  } else {
    return response.json();
  }
}
