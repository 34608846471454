import React, { useState, createContext, useEffect, useContext } from "react";
import { JiraIssueResponse, getIssue } from "../../services/jira-api";
import { LoadingPage } from "../../components/LoadingPage";
import { ServerErrorMessage } from "../../components/messages/ServerErrorMessage";
import {
  ISSUE_LINK_FIELD,
  PORTAL_LINK_FIELD,
  getIssueUrl,
  getPortalUrl,
} from "../../services/utils";
import { useApplicationContext } from "./ApplicationContextProvider";
import { useProjectContext } from "./ProjectContextProvider";

interface Issue {
  issue: JiraIssueResponse;
}

const IssueContext = createContext<Issue | null>(null);

interface IssueContextProps {
  children: React.ReactNode;
}

export const IssueContextProvider = ({ children }: IssueContextProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [issue, setIssue] = useState<JiraIssueResponse | null>(null);
  const { baseUrl, displayUrlServicedeskHelpCenter, hostBaseUrl } =
    useApplicationContext();
  const { serviceDeskId } = useProjectContext();
  useEffect(() => {
    void (async () => {
      try {
        const issue = await getIssue();
        const issueLink = getIssueUrl(baseUrl, issue);
        const portalLink = serviceDeskId
          ? getPortalUrl(
              displayUrlServicedeskHelpCenter ?? hostBaseUrl,
              serviceDeskId,
              issue.key
            )
          : undefined;
        setIssue({
          ...issue,
          fields: {
            ...issue.fields,
            [ISSUE_LINK_FIELD.id]: issueLink,
            [PORTAL_LINK_FIELD.id]: portalLink,
          },
        });
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [baseUrl, displayUrlServicedeskHelpCenter, hostBaseUrl, serviceDeskId]);

  if (isLoading) {
    return <LoadingPage />;
  }
  if (!issue) {
    return <ServerErrorMessage />;
  }
  return (
    <IssueContext.Provider value={{ issue }}>{children}</IssueContext.Provider>
  );
};

export function useIssueContext() {
  const context = useContext(IssueContext);
  if (!context) {
    throw new Error(
      "Issue context not initialized, probably context provider is missing"
    );
  }
  return context;
}
