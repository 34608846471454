import React, { useEffect } from "react";
import { getErrorStatus } from "../../services/utils";
import { DefaultSectionMessage } from "./DefaultSectionMessage";
import { ServerErrorMessage } from "./ServerErrorMessage";

interface CalendarErrorMessageProps {
  error: any;
  onClose: () => void;
  refreshEvents?: () => void;
}

export function CalendarErrorMessage({
  error,
  onClose,
  refreshEvents,
}: CalendarErrorMessageProps) {
  const errorStatus = getErrorStatus(error);
  const isCalendarPermissionOrNotFoundError =
    errorStatus === 403 || errorStatus === 404;

  useEffect(() => {
    if (refreshEvents && isCalendarPermissionOrNotFoundError) {
      refreshEvents();
    }
  }, [refreshEvents, isCalendarPermissionOrNotFoundError]);

  if (isCalendarPermissionOrNotFoundError) {
    return (
      <DefaultSectionMessage
        appearance="error"
        title="Error occurred"
        onClose={onClose}
      >
        Calendar doesn&apos;t exist, or you don&apos;t have access to it.
      </DefaultSectionMessage>
    );
  } else {
    return <ServerErrorMessage />;
  }
}
