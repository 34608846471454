import React, { FC } from "react";
import styled from "styled-components";
import { N200 } from "@atlaskit/theme/colors";
import { fontSize } from "@atlaskit/theme/constants";
import { headingSizes } from "@atlaskit/theme/typography";
import { token } from "@atlaskit/tokens";

const LabelElement = styled.label`
  font-size: ${headingSizes.h200.size / fontSize()}em;
  font-style: inherit;
  line-height: ${headingSizes.h200.lineHeight / headingSizes.h200.size};
  color: ${token("color.text.subtle", N200)};
  font-weight: 600;
  display: inline-block;
  margin-bottom: 2px;
  margin-top: 0;
`;

export const Label: FC<React.LabelHTMLAttributes<HTMLLabelElement>> = (
  props
) => {
  return (
    <div>
      <LabelElement {...props} />
    </div>
  );
};
