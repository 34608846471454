import { useEffect, useRef, useState } from "react";

export const usePrevious = <T>(value: T): T | undefined => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const useScript = (url: string) => {
  const [status, setStatus] = useState("idle");
  useEffect(() => {
    const script = document.createElement("script");

    script.src = url;
    script.async = true;

    document.body.appendChild(script);

    function handleEvent(event: Event) {
      setStatus(event.type === "load" ? "ready" : "error");
    }

    script.addEventListener("load", handleEvent);
    script.addEventListener("error", handleEvent);

    return () => {
      script.removeEventListener("load", handleEvent);
      script.removeEventListener("error", handleEvent);

      document.body.removeChild(script);
    };
  }, [url]);

  return { status };
};

export default function useIsVisibleElement(element: Element | null) {
  const observerRef = useRef<IntersectionObserver | null>(null);
  const [isOnScreen, setIsOnScreen] = useState(false);

  useEffect(() => {
    observerRef.current = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        setIsOnScreen(entry.isIntersecting);
      }
    });
  }, []);

  useEffect(() => {
    if (element) {
      observerRef.current?.observe(element);
    }

    return () => {
      if (element) {
        observerRef.current?.disconnect();
      }
    };
  }, [element]);

  return isOnScreen;
}
