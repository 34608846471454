import React, { useCallback, useEffect, useState } from "react";
import { getEvents, Event } from "../services/calendar";
import { AttendeesContextProvider } from "./Providers/AttendeesProvider";

interface EventsDataProviderChildrenProps {
  events: Event[];
  loadingEvents: boolean;
  refreshEvents: () => void;
}

interface EventsDataProviderProps {
  children: (args: EventsDataProviderChildrenProps) => React.ReactNode;
  issueKey: string;
}

export const EventsDataProvider = ({
  children,
  issueKey,
}: EventsDataProviderProps) => {
  const [loadingEvents, setLoadingEvents] = useState<boolean>(false);
  const [fetchedEvents, setFetchedEvents] = useState<Event[]>([]);

  const fetchEvents = () => {
    getEvents({ issueKey })
      .then((events) => {
        setFetchedEvents(events);
        setLoadingEvents(false);
      })
      .catch((error) => {
        setLoadingEvents(false);
        console.error(error);
      });
  };

  const fetchAllEvents = useCallback(() => {
    setLoadingEvents(true);
    fetchEvents();
  }, []);

  useEffect(() => {
    fetchAllEvents();
  }, [fetchAllEvents]);

  return (
    <AttendeesContextProvider events={fetchedEvents || []}>
      {children({
        events: fetchedEvents ? fetchedEvents : [],
        refreshEvents: fetchAllEvents,
        loadingEvents: loadingEvents,
      })}
    </AttendeesContextProvider>
  );
};
