import { set, ref, child, remove } from "firebase/database";
import { useDatabase, useDatabaseObjectData } from "reactfire";
import { useCallback, useMemo } from "react";
import { Configuration, DescriptionAutomationData } from "../types";
import { useApplicationContext } from "../screens/Providers/ApplicationContextProvider";

function sanitizeFirebaseKey(key: string) {
  return encodeURIComponent(key).replace(/\./g, "%2E");
}

const useUserRef = () => {
  const database = useDatabase();
  const { clientKey, userAccountId } = useApplicationContext();
  return useMemo(() => {
    const usersRef = ref(database, "users");
    return child(
      usersRef,
      `${sanitizeFirebaseKey(clientKey)}/${userAccountId}`
    );
  }, [clientKey, database, userAccountId]);
};

const useConfigurationRef = () => {
  const database = useDatabase();
  const { clientKey } = useApplicationContext();
  return useMemo(() => {
    const settingsRef = ref(database, "configuration");
    return child(settingsRef, sanitizeFirebaseKey(clientKey));
  }, [clientKey, database]);
};

export const useConfigurationChange = () => {
  const configurationRef = useConfigurationRef();
  return async (settings: Configuration) => set(configurationRef, settings);
};

export const useConfiguration = () => {
  const configurationRef = useConfigurationRef();
  return useDatabaseObjectData<Configuration>(configurationRef);
};

export const useIsAuthorized = () => {
  const userRef = useUserRef();
  const isAuthorizedRef = child(userRef, `authorized`);
  return useDatabaseObjectData<boolean>(isAuthorizedRef);
};

export function useProjectCalendarIdRef(projectKey: string) {
  const userRef = useUserRef();
  return child(userRef, `/projectsCalendars/${projectKey}`);
}

export function useProjectCalendarIdUpdate(projectKey: string) {
  const projectCalendarIdRef = useProjectCalendarIdRef(projectKey);
  return (calendarId: string) => set(projectCalendarIdRef, calendarId);
}

export function useProjectCalendarIdRemove(projectKey: string) {
  const projectCalendarIdRef = useProjectCalendarIdRef(projectKey);
  return () => remove(projectCalendarIdRef);
}

export function useDescriptionAutomationRef(projectKey: string) {
  const userRef = useUserRef();
  return useMemo(
    () => child(userRef, `descriptionAutomation/${projectKey}`),
    [userRef, projectKey]
  );
}

export function useDescriptionAutomationData(projectKey: string) {
  const descriptionAutomationRef = useDescriptionAutomationRef(projectKey);
  return useDatabaseObjectData<DescriptionAutomationData>(
    descriptionAutomationRef
  );
}

export function useSetDescriptionFields() {
  const { jira } = useApplicationContext();
  const descriptionAutomationRef = useDescriptionAutomationRef(
    jira.project.key
  );
  const descriptionFieldsRef = useMemo(
    () => child(descriptionAutomationRef, "fields"),
    [descriptionAutomationRef]
  );
  return useCallback(
    (fieldsIds: string[]) => set(descriptionFieldsRef, fieldsIds),
    [descriptionFieldsRef]
  );
}

export function useSetWasDescriptionFieldsSet() {
  const { jira } = useApplicationContext();
  const descriptionAutomationRef = useDescriptionAutomationRef(
    jira.project.key
  );
  const wasSetRef = useMemo(
    () => child(descriptionAutomationRef, "wasSet"),
    [descriptionAutomationRef]
  );
  return useCallback(() => set(wasSetRef, true), [wasSetRef]);
}
