import React from "react";
import Spinner from "@atlaskit/spinner";
import styled from "styled-components";
import { MARGIN, MINIMUM_HEIGHT } from "./ResizeContext";

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  min-height: ${MINIMUM_HEIGHT - MARGIN}px;
`;

export function LoadingPage() {
  return (
    <Center>
      <Spinner size="large" />
    </Center>
  );
}
