import React, {
  ReactNode,
  useState,
  useEffect,
  createContext,
  useMemo,
  useContext,
  Dispatch,
  SetStateAction,
} from "react";

import {
  ISSUE_LINK_FIELD,
  PORTAL_LINK_FIELD,
  getAllDescriptionFields,
  isJSMProject,
  validateField,
} from "../../services/utils";
import {
  useDescriptionAutomationData,
  useSetDescriptionFields,
  useSetWasDescriptionFieldsSet,
} from "../../services/firebase";
import { JiraField } from "../../types";
import { useApplicationContext } from "./ApplicationContextProvider";
import { useProjectContext } from "./ProjectContextProvider";

interface DescriptionFieldsContextProviderProps {
  children: ReactNode;
}

interface DescriptionFieldsContextData {
  issueFields: JiraField[];
  loading: boolean;
  error: boolean;
  descriptionFieldsIds: string[];
  selectedValues: string[];
  setSelectedValues: Dispatch<SetStateAction<string[]>>;
}

const DescriptionFieldsContext = createContext<DescriptionFieldsContextData>({
  issueFields: [],
  loading: true,
  error: false,
  descriptionFieldsIds: [],
  selectedValues: [],
  setSelectedValues: () => null,
});

export function DescriptionFieldsContextProvider({
  children,
}: DescriptionFieldsContextProviderProps) {
  const [issueFields, setIssueFields] = useState<JiraField[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const { jira } = useApplicationContext();
  const { project } = useProjectContext();
  const { data, status } = useDescriptionAutomationData(jira.project.key);
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const setFields = useSetDescriptionFields();
  const setWasDescriptionFieldsSet = useSetWasDescriptionFieldsSet();

  useEffect(() => {
    getAllDescriptionFields()
      .then((allFields) => {
        setIssueFields(
          allFields.filter((field) => validateField(field, project))
        );
      })
      .catch((error) => {
        console.error("Unexpected error while fetching jira fields", error);
        setError(true);
      })
      .finally(() => setLoading(false));
  }, [project]);

  useEffect(() => {
    if (!loading && !error && status === "success" && !data?.wasSet) {
      const isJSM = isJSMProject(project);
      const fieldToSet = isJSM ? PORTAL_LINK_FIELD : ISSUE_LINK_FIELD;
      void setFields([fieldToSet.id]);
      void setWasDescriptionFieldsSet();
    }
  }, [
    data?.wasSet,
    error,
    loading,
    project,
    setFields,
    setWasDescriptionFieldsSet,
    status,
  ]);

  const descriptionFieldsContext = useMemo(
    () => ({
      issueFields,
      loading: loading || status === "loading",
      error: error || status === "error",
      descriptionFieldsIds: data?.fields || [],
      selectedValues,
      setSelectedValues,
    }),
    [data, error, issueFields, loading, status, selectedValues]
  );

  return (
    <DescriptionFieldsContext.Provider value={descriptionFieldsContext}>
      {children}
    </DescriptionFieldsContext.Provider>
  );
}

export function useDescriptionFieldsContext() {
  const context = useContext(DescriptionFieldsContext);
  if (!context) {
    throw new Error(
      "Description Fields context not initialized, probably context provider is missing"
    );
  }
  return context;
}
