import React, { useMemo, useEffect, useState } from "react";
import styled from "styled-components";
import { OptionsType, CheckboxSelect, OptionType } from "@atlaskit/select";
import { ErrorMessage } from "@atlaskit/form";
import { useDescriptionFieldsContext } from "../screens/Providers/DescriptionFieldsContextProvider";

const CUSTOM_FIELDS_DROPDOWN_HEIGHT = 200;

const DropdownPlaceholder = styled.div`
  height: ${CUSTOM_FIELDS_DROPDOWN_HEIGHT}px;
`;

export default function DescriptionFieldsSelect() {
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const {
    issueFields,
    error,
    loading,
    descriptionFieldsIds,
    selectedValues,
    setSelectedValues,
  } = useDescriptionFieldsContext();
  const options = issueFields.map((field) => ({
    label: field.name,
    value: field.id,
  }));

  const onSelectChange = (selectedFields: OptionsType<OptionType>) => {
    setSelectedValues(
      selectedFields.map((selectedField) => selectedField.value as string)
    );
  };

  const onDropdownOpen = () => {
    setIsDropdownOpen(true);
  };

  const onDropdownClose = () => {
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    setSelectedValues(descriptionFieldsIds);
  }, [setSelectedValues, descriptionFieldsIds]);

  const selectedOptions = useMemo(
    () => options.filter((option) => selectedValues?.includes(option.value)),
    [selectedValues, options]
  );

  return (
    <>
      <CheckboxSelect
        isLoading={loading}
        options={options}
        onChange={onSelectChange}
        onMenuOpen={onDropdownOpen}
        onMenuClose={onDropdownClose}
        maxMenuHeight={CUSTOM_FIELDS_DROPDOWN_HEIGHT}
        value={selectedOptions}
        isMulti
        isInvalid={error}
      />
      {isDropdownOpen && <DropdownPlaceholder></DropdownPlaceholder>}
      {error && <ErrorMessage>Couldn&apos;t fetch jira fields</ErrorMessage>}
    </>
  );
}
