import React, { useState, createContext, useEffect, useContext } from "react";
import { useDatabaseObjectData } from "reactfire";
import {
  getCalendars,
  Calendar,
  getPrimaryCalendarId,
  getCalendarById,
} from "../services/calendar";
import {
  useProjectCalendarIdRef,
  useProjectCalendarIdRemove,
} from "../services/firebase";
import { LoadingPage } from "./LoadingPage";
import { ServerErrorMessage } from "./messages/ServerErrorMessage";

interface CalendarsDataProviderContext {
  calendars: Calendar[];
  projectCalendar: Calendar;
  primaryCalendarId: string;
}
const CalendarsDataContext = createContext<CalendarsDataProviderContext | null>(
  null
);

interface CalendarsDataProviderProps {
  children?: React.ReactNode;
  projectKey: string;
}

export const CalendarsDataProvider = ({
  children,
  projectKey,
}: CalendarsDataProviderProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [calendars, setCalendars] = useState<Calendar[]>([]);
  const [projectCalendar, setProjectCalendar] = useState<Calendar | undefined>(
    undefined
  );
  const [primaryCalendarId, setPrimaryCalendarId] = useState<
    string | undefined
  >(undefined);

  const projectCalendarIdRef = useProjectCalendarIdRef(projectKey);
  const { status, data: projectCalendarId } =
    useDatabaseObjectData<string>(projectCalendarIdRef);
  const removeProjectCalendarId = useProjectCalendarIdRemove(projectKey);

  useEffect(() => {
    if (status === "success") {
      getCalendars()
        .then(({ calendars }: { calendars: Calendar[] }) => {
          setCalendars(calendars);
        })
        .catch((err) => {
          console.error(err);
          setIsLoading(false);
        });
    }
  }, [status]);

  useEffect(() => {
    if (calendars.length) {
      const primaryCalendarId = getPrimaryCalendarId(calendars);
      setPrimaryCalendarId(primaryCalendarId);
    }
  }, [calendars]);

  useEffect(() => {
    if (calendars.length && primaryCalendarId && status === "success") {
      const projectCalendar = getCalendarById(calendars, projectCalendarId);
      setProjectCalendar(
        projectCalendar || getCalendarById(calendars, primaryCalendarId)
      );
      if (!projectCalendar && projectCalendarId) {
        removeProjectCalendarId().catch(console.error);
      }
      setIsLoading(false);
    }
  }, [
    calendars,
    primaryCalendarId,
    status,
    projectCalendarId,
    removeProjectCalendarId,
  ]);

  if (isLoading || status === "loading") {
    return <LoadingPage />;
  }
  if (!projectCalendar || !primaryCalendarId) {
    return <ServerErrorMessage />;
  }
  return (
    <CalendarsDataContext.Provider
      value={{
        calendars,
        projectCalendar,
        primaryCalendarId,
      }}
    >
      {children}
    </CalendarsDataContext.Provider>
  );
};

export function useCalendarsContext() {
  const calendarsContext = useContext(CalendarsDataContext);
  if (!calendarsContext) {
    throw new Error(
      "Calendars context not initialized, probably context provider is missing"
    );
  }
  return calendarsContext;
}
