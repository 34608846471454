import { SectionMessageAction } from "@atlaskit/section-message";
import React from "react";
import { DOCUMENTATION } from "../../services/variables";
import { useCalendarsContext } from "../CalendarsDataProvider";
import { DefaultSectionMessage } from "./DefaultSectionMessage";

interface SuccessfulLoginMessageProps {
  isSuccess: boolean;
  onClose: () => void;
}

export function SuccessfulLoginMessage({
  isSuccess,
  onClose,
}: SuccessfulLoginMessageProps) {
  const { primaryCalendarId } = useCalendarsContext();
  if (isSuccess) {
    return (
      <DefaultSectionMessage
        appearance="success"
        title="Google calendar connected"
        onClose={onClose}
        actions={[
          <SectionMessageAction
            onClick={() => window.open(DOCUMENTATION, "_blank")}
            key="documentation"
          >
            User guide
          </SectionMessageAction>,
          <SectionMessageAction key="close" onClick={onClose}>
            Close
          </SectionMessageAction>,
        ]}
      >
        <p>
          <strong>{primaryCalendarId}</strong> account has been connected
          successfully.
        </p>
      </DefaultSectionMessage>
    );
  } else {
    return null;
  }
}
