import React, {
  useState,
  createContext,
  useContext,
  Dispatch,
  SetStateAction,
} from "react";
import { FeedbackForm } from "./FeedbackForm";

interface FeedbackProviderContext {
  isFeedbackOpen: boolean;
  setIsFeedbackOpen: Dispatch<SetStateAction<boolean>>;
}
const FeedbackContext = createContext<FeedbackProviderContext | null>(null);

interface FeedbackProviderProps {
  children?: React.ReactNode;
}

export const FeedbackProvider = ({ children }: FeedbackProviderProps) => {
  const [isFeedbackOpen, setIsFeedbackOpen] = useState<boolean>(false);

  const renderFeedback = () => {
    if (isFeedbackOpen) {
      return <FeedbackForm onClose={() => setIsFeedbackOpen(false)} />;
    } else {
      return children;
    }
  };
  return (
    <FeedbackContext.Provider
      value={{
        isFeedbackOpen,
        setIsFeedbackOpen,
      }}
    >
      {renderFeedback()}
    </FeedbackContext.Provider>
  );
};

export function useFeedbackContext() {
  const feedbackContext = useContext(FeedbackContext);
  if (!feedbackContext) {
    throw new Error(
      "Feedback context not initialized, probably context provider is missing"
    );
  }
  return feedbackContext;
}
