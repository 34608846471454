import { JiraContext, JiraField, JiraProject, User } from "../types";

export const getJiraToken = async () => AP.context.getToken();
export const getContext = async () => AP.context.getContext();

declare global {
  interface Window {
    GOOGLE_CALENDAR: {
      stopEmitBadgeClickedEvent: boolean;
    };
  }
}

export interface JiraIssueResponse {
  id: string;
  fields: {
    summary: string;
    project: {
      name: string;
      key: string;
      id: string;
    };
    [key: string]: unknown;
  };
  renderedFields: Record<string, unknown>;
  key: string;
  self: string;
}

export const getIssue: () => Promise<JiraIssueResponse> = async () => {
  const { jira } = (await getContext()) as JiraContext;
  const result = await AP.request({
    // Using the 'data' param instead of puting query params directly
    // in URL breaks the app in Android Jira app for some reason
    url: `/rest/api/3/issue/${jira.issue.key}?expand=renderedFields`,
    // data: { fields: ["summary", "project"] },
    contentType: "application/json",
  });
  return JSON.parse(result.body) as JiraIssueResponse;
};

interface ProjectProperty<T> {
  key: string;
  value: T;
}

export async function getProjectProperty<T>(
  key: string,
  defaultValue: T
): Promise<T> {
  try {
    const { jira } = (await getContext()) as JiraContext;
    const result = await AP.request({
      url: `/rest/api/3/project/${jira.project.key}/properties/${key}`,
    });
    const property = JSON.parse(result.body) as ProjectProperty<T>;
    return property.value;
  } catch (error) {
    if (defaultValue !== undefined) {
      return await setProjectProperty(key, defaultValue);
    }
    throw error;
  }
}

export async function setProjectProperty<T>(key: string, value: T) {
  const { jira } = (await getContext()) as JiraContext;
  return await AP.request({
    url: `/rest/api/3/project/${jira.project.key}/properties/${key}`,
    type: "PUT",
    data: JSON.stringify(value),
    contentType: "application/json",
  }).then(() => value);
}

export async function searchUsers(query = "") {
  const result = await AP.request(`/rest/api/3/user/search?query=${query}`);
  return JSON.parse(result.body) as User[];
}

export async function getIssueFields() {
  const result = await AP.request("/rest/api/3/field");
  return JSON.parse(result.body) as JiraField[];
}

export async function getProject(projectId: string) {
  const result = await AP.request(`/rest/api/3/project/${projectId}`);
  return JSON.parse(result.body) as JiraProject;
}

interface JiraServiceDesk {
  id: string;
  projectId: string;
  projectKey: string;
  projectName: string;
}

export async function getProjectServiceDeskId(projectId: string) {
  const result = await AP.request(
    `/rest/servicedeskapi/servicedesk/projectId:${projectId}`
  );
  return JSON.parse(result.body) as JiraServiceDesk;
}
