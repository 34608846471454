import React, { SyntheticEvent, useState } from "react";
import { RadioGroup } from "@atlaskit/radio";
import { DatePicker } from "@atlaskit/datetime-picker";
import TextField from "@atlaskit/textfield";
import { Field } from "@atlaskit/form";
import styled from "styled-components";
import { OptionsPropType } from "@atlaskit/radio/types";
import { Options } from "rrule";

const radioOptions: OptionsPropType = [
  { label: "Never", value: "never" },
  { label: "On", value: "on" },
  { label: "After", value: "after" },
];

enum RecurrenceEndType {
  ON = "on",
  AFTER = "after",
  NEVER = "never",
}

interface RecurrenceEndSelectProps {
  fromDate: string;
  rrule: Partial<Options>;
  onUpdateRrule: (rrule: Partial<Options>) => void;
}

export function RecurrenceEndSelect({
  fromDate,
  rrule,
  onUpdateRrule,
}: RecurrenceEndSelectProps) {
  const [selectedEndType, setSelectedEndType] = useState<RecurrenceEndType>(
    getInitialSelectedEndType(rrule)
  );

  const selectEndType = (event: SyntheticEvent<HTMLInputElement>) => {
    const option = event.currentTarget.value as RecurrenceEndType;
    switch (option) {
      case RecurrenceEndType.NEVER:
        onUpdateRrule({ until: undefined, count: undefined });
        break;
      case RecurrenceEndType.ON:
        onUpdateRrule({
          until: new Date(fromDate),
          count: undefined,
        });
        break;
      default:
        onUpdateRrule({ until: undefined, count: 1 });
    }

    setSelectedEndType(option);
  };

  const recurrencesNumber =
    rrule.count !== undefined &&
    rrule.count !== null &&
    !isNaN(Number(rrule.count))
      ? Number(rrule.count)
      : 1;

  const updateRecurrencesNumber = (event: SyntheticEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    onUpdateRrule({
      count: Number(target.value),
    });
  };

  const defaultRecurrenceEndDate = rrule.until
    ? convertToDateFormat(rrule.until)
    : fromDate;

  const updateRecurrenceDate = (value: string) =>
    onUpdateRrule({
      until: value ? new Date(value) : undefined,
    });

  return (
    <Field name="ends" label="Ends">
      {() => (
        <RecurrenceEndTable>
          <tbody>
            <tr>
              <RadioGroupCell rowSpan={3}>
                <RadioGroup
                  options={radioOptions}
                  defaultValue={selectedEndType}
                  onChange={selectEndType}
                />
              </RadioGroupCell>
              <td> </td>
            </tr>
            <tr>
              <td>
                <DatePicker
                  isDisabled={selectedEndType !== "on"}
                  dateFormat="EEEE, MMMM DD"
                  value={defaultRecurrenceEndDate}
                  onChange={updateRecurrenceDate}
                />
              </td>
            </tr>
            <tr>
              <CountInputCell>
                <TextField
                  type="number"
                  min={1}
                  step={1}
                  isDisabled={selectedEndType !== "after"}
                  value={recurrencesNumber}
                  onChange={updateRecurrencesNumber}
                />
                <span>occurrences</span>
              </CountInputCell>
            </tr>
          </tbody>
        </RecurrenceEndTable>
      )}
    </Field>
  );
}

function getInitialSelectedEndType(rrule: Partial<Options>) {
  if (rrule?.until) {
    return RecurrenceEndType.ON;
  }
  return rrule?.count ? RecurrenceEndType.AFTER : RecurrenceEndType.NEVER;
}

function convertToDateFormat(date: Date) {
  return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
    2,
    "0"
  )}-${String(date.getDate()).padStart(2, "0")}`;
}

const RecurrenceEndTable = styled.table`
  margin-top: 0;

  tbody {
    border: none;
  }

  tr {
    height: 44px;
  }

  td {
    padding: 0;
  }
`;

const CountInputCell = styled.td`
  display: flex;
  align-items: center;
  gap: 4px;

  & > div {
    max-width: 80px;
  }
`;

const RadioGroupCell = styled.td`
  width: 150px;
  label {
    display: flex;
    align-items: center;
    height: 44px;

    & > div {
      width: 100%;
    }
  }
`;
