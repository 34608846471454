import React, { useCallback } from "react";
import { Field, HelperMessage } from "@atlaskit/form";
import Select, { OptionType, ValueType } from "@atlaskit/select";
import { getTimeZone, TIMEZONES } from "../services/timezones";
import { Calendar } from "../services/calendar";

interface TimeZoneSelectProps {
  calendar: Calendar;
  selectedTimeZone?: string;
  onTimeZoneChange: (timeZone: string | undefined) => void;
}

export function TimeZoneSelect({
  selectedTimeZone,
  calendar,
  onTimeZoneChange,
}: TimeZoneSelectProps) {
  const timeZoneOption: OptionType | undefined = selectedTimeZone
    ? getTimeZone(selectedTimeZone)
    : getTimeZone(calendar.timeZone);

  const onChange = useCallback(
    (option: OptionType | null) => {
      const timeZone = option?.value as string | undefined;
      onTimeZoneChange(timeZone);
    },
    [onTimeZoneChange]
  );

  return (
    <Field<ValueType<OptionType>>
      aria-required={true}
      name="timeZone"
      id="timeZone"
    >
      {({ fieldProps: { id, ...rest } }) => (
        <>
          <Select
            id={`${id}-select`}
            options={TIMEZONES}
            {...rest}
            value={timeZoneOption}
            onChange={(e) => onChange(e)}
          />
          <HelperMessage>Select the time zone for the event</HelperMessage>
        </>
      )}
    </Field>
  );
}
