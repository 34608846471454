import React, { useState } from "react";
import Button from "@atlaskit/button";
import { Field } from "@atlaskit/form";
import TextField from "@atlaskit/textfield";
import ArrowLeftCircleIcon from "@atlaskit/icon/glyph/arrow-left-circle";
import Select from "@atlaskit/select";
import styled from "styled-components";
import { Frequency, Options } from "rrule";
import {
  DayOfWeek,
  findRecurrencePresetByValue,
  RecurrenceOption,
  RecurrencePresetOption,
  recurrenceToRruleProperties,
  rrulePropertiesEquals,
  rrulePropertiesToRecurrence,
} from "../../services/recurrence-utils";
import { WeekdaySelector } from "./WeekdaySelector";
import useMonthlyRecurrencePresets from "./useMonthlyRecurrencePresets";
import { RecurrenceEndSelect } from "./RecurrenceEndSelect";

interface FreqOption {
  label: string;
  value: Frequency;
}

const FreqOptions: FreqOption[] = [
  { label: "Day", value: Frequency.DAILY },
  { label: "Week", value: Frequency.WEEKLY },
  { label: "Month", value: Frequency.MONTHLY },
  { label: "Year", value: Frequency.YEARLY },
];

interface CustomRecurrenceSelectProps {
  fromDate: string;
  weekStartDay: DayOfWeek;
  recurrence: string | undefined;
  onRecurrenceChanged: (recurrence: string | undefined) => void;
  recurrencePresets: RecurrencePresetOption[];
  onRecurrencePresetChanged: (
    preset: RecurrencePresetOption | undefined
  ) => void;
  onCloseDialog: () => void;
}

export function CustomRecurrenceDialog({
  fromDate,
  recurrence,
  onRecurrenceChanged,
  recurrencePresets,
  onRecurrencePresetChanged,
  onCloseDialog,
}: CustomRecurrenceSelectProps) {
  const [customRrule, setCustomRrule] = useState<Partial<Options>>(
    getInitialRrule(recurrence)
  );

  const {
    monthlyRecurrencePreset,
    defaultMonthlyRecurrencePreset,
    setMonthlyRecurrencePreset,
    monthlyRecurrencePresets,
    refreshMonthlyRecurrencePreset,
  } = useMonthlyRecurrencePresets({ fromDate, rrule: customRrule });

  const updateCustomRrule = (update: Partial<Options>) => {
    setCustomRrule({
      ...customRrule,
      ...update,
    });
  };

  const updateFreqAndInterval = (update: RecurrenceOption) => {
    const selectedOption = monthlyRecurrencePresets.find((option) =>
      rrulePropertiesEquals(option.value, update.value)
    );

    if (selectedOption) {
      setMonthlyRecurrencePreset(selectedOption);
      const { freq, byweekday } = selectedOption.value;
      updateCustomRrule({
        freq,
        byweekday,
        interval: customRrule.interval,
      });
    }
  };

  const onSave = () => {
    if (customRrule) {
      onRecurrenceChanged(rrulePropertiesToRecurrence(customRrule));
    }
    onCloseDialog();
  };

  const onCancel = () => {
    onRecurrenceChanged(recurrence);
    const presetOption = findRecurrencePresetByValue(
      recurrence,
      recurrencePresets
    );
    onRecurrencePresetChanged(presetOption);
    onCloseDialog();
  };

  return (
    <Wrapper>
      <h4>Custom recurrence</h4>
      <div>
        <Field name="repeat-every" label="Repeat every">
          {() => (
            <FreqAndIntervalWrapper>
              <Flex flex="1">
                <TextField
                  type="number"
                  min={1}
                  step={1}
                  required={true}
                  value={customRrule.interval}
                  onChange={(event) => {
                    const target = event.target as HTMLInputElement;
                    const interval = Number(target.value);
                    updateCustomRrule({
                      interval,
                    });
                    refreshMonthlyRecurrencePreset(interval);
                  }}
                />
              </Flex>
              <Flex flex="2">
                <Select
                  value={FreqOptions.find(
                    (freq) => freq.value === customRrule.freq
                  )}
                  options={FreqOptions}
                  onChange={(update) => {
                    if (update) {
                      if (update.value !== Frequency.MONTHLY) {
                        setMonthlyRecurrencePreset(
                          defaultMonthlyRecurrencePreset
                        );
                      }
                      updateCustomRrule({
                        freq: update.value,
                        byweekday: undefined,
                      });
                    }
                  }}
                />
              </Flex>
            </FreqAndIntervalWrapper>
          )}
        </Field>
        {customRrule.freq === Frequency.WEEKLY && (
          <Field name="repeat-on" label="Repeat on">
            {() => (
              <WeekdaySelector
                selectedWeekdays={customRrule.byweekday}
                onWeekdayClick={(update) => {
                  updateCustomRrule({
                    byweekday: update,
                  });
                }}
              />
            )}
          </Field>
        )}
        {customRrule.freq === Frequency.MONTHLY && (
          <Field name="repeat-on" label="Repeat on">
            {() => (
              <Select
                value={monthlyRecurrencePreset}
                options={monthlyRecurrencePresets}
                onChange={(update) => update && updateFreqAndInterval(update)}
              />
            )}
          </Field>
        )}
      </div>
      <RecurrenceEndSelect
        fromDate={fromDate}
        rrule={customRrule}
        onUpdateRrule={updateCustomRrule}
      />

      <Footer>
        <Button
          appearance="subtle"
          iconBefore={<ArrowLeftCircleIcon label="Back" />}
          onClick={onCancel}
        >
          Back
        </Button>

        <Button type="button" appearance="primary" onClick={onSave}>
          Save
        </Button>
      </Footer>
    </Wrapper>
  );
}

function getInitialRrule(recurrence: string | undefined) {
  return recurrence && recurrence !== "custom"
    ? recurrenceToRruleProperties(recurrence)
    : {
        freq: Frequency.WEEKLY,
        interval: 1,
      };
}

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: 0;
  padding: 8px;
  background: var(--ds-surface-overlay, #ffffff);
  z-index: 300;
`;

const FreqAndIntervalWrapper = styled.div`
  display: flex;
  gap: 12px;
`;

const Flex = styled.div<{ flex: string }>`
  flex: ${({ flex }) => flex};
`;

const Footer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  gap: 8px;
  margin-top: 32px;
`;
