import { useMemo, useState } from "react";
import {
  findRecurrencePresetByValue,
  generateRecurrencePresetOptions,
  RecurrencePresetOption,
} from "../../services/recurrence-utils";

interface UseRecurrencePresetsProps {
  recurrence: string | undefined;
  fromDate?: string;
  fromTime?: string;
  timeZone?: string;
}
export default function useRecurrencePresets({
  recurrence,
  fromDate,
  fromTime,
  timeZone,
}: UseRecurrencePresetsProps) {
  const initialRecurrencePreset = fromDate
    ? findRecurrencePresetByValue(
        recurrence,
        generateRecurrencePresetOptions(
          fromDate,
          fromTime,
          timeZone,
          recurrence
        )
      )
    : undefined;

  const [recurrencePreset, setRecurrencePreset] = useState<
    RecurrencePresetOption | undefined
  >(initialRecurrencePreset);

  const recurrencePresets = useMemo(
    () =>
      fromDate
        ? generateRecurrencePresetOptions(
            fromDate,
            fromTime,
            timeZone,
            recurrence
          )
        : [],
    [fromDate, fromTime, recurrence, timeZone]
  );

  return {
    recurrencePreset,
    setRecurrencePreset,
    recurrencePresets,
  };
}
